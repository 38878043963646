.std-box {
  width: 100%;
  border: 1px solid #dfdfdf;
  cursor: pointer;
}

.std-box img {
  width: 100%;
  margin-bottom: 5px;
}

.std-box video {
  width: 100%;
  margin-bottom: 5px;
}

.std-box h6 {
  font-size: 18px;
  padding: 0 15px;
  margin: 0 0 10px 0;
}

.std-box p {
  width: 100%;
  padding: 0 15px;
  font-size: 13px;
  margin: 0 0 15px 0;
}

.mt-input {
  border: 1px solid #d2d6da;
  width: 100%;
  border-radius: 6px;
  padding: 11px 14px;
}

.mt-30 {
  margin-top: 30px;
}


.VideoInput {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #097da0;
  width: 100%;
}

.VideoInput_input {
  display: none;
}

.VideoInput_video {
  display: block;
  margin: 0;
  /* margin-left: 200px; */
  padding-top: 20px;
  width: 95%;
}

.VideoInput_footer {
  background: #96ccf0;
  width: 60%;
  min-height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
}

.pdf-container{
  display: flex;
  justify-content: center;
  user-select: none;
  
}
.wrap{
  margin: auto 5rem;

}
.controls{
  margin: 20px;
  justify-content: center;
}
.controls button{
  padding: 1rem 2rem;
  background-color: black;
  color: white;
  border-radius: 5px;
  margin-right: 2rem;
  outline: none;
  border: none;
  box-shadow: 1px 2px 5px black;
  cursor: pointer;
}

@media print{
  .pdf-container{
    display: none;
  }
}



.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.prevent-select{
  pointer-events: not-allowed! important;
  cursor: not-allowed! important;
}
.not-allowed{
cursor: not-allowed! important;
 
} 

.react-pdf__Page__textContent{
  user-select: none;
}

.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #cc0000;
}

#contextmenu{
    /* -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none; */
    user-select: none;
    pointer-events:none;
    /* cursor: none; */
    /* overflow-y: scroll; */
}

.contextmenu{
  position: absolute;
  top: 110;
  left: 0;
  width: 100%;
  /* height: 3600vh; */
  pointer-events: none;
}


#app{
  user-select: none;
  /* pointer-events: all; */
}

@media print {
  .contextmenu {
    display: none;
  }
}



/* @media screen {
  .contextmenu {
    user-select: none;
  }
} */
